import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import LoginScreen from './screens/LoginScreen';
import { useEffect } from 'react';
import { AppContext } from './Context';
import { useImmerReducer } from 'use-immer';
import CrmPage from './screens/crmPage';
import ProtectedRoutes from './ProtectedRoutes';
import AdminRoutes from './AdminRoutes';
import StateContext from './StateContext';
import UnderConstruction from "./screens/UnderConstruction";
import ParkingForm from './screens/ParkingForm';

//In this file we will put all the components
//And Routes

const App = () => {
  const initialState = {
    loggedIn: Boolean(localStorage.getItem('token')),
    authenticated: Boolean(localStorage.getItem('authenticated')),
    user: {
      token: localStorage.getItem('token'),
      agent_id: localStorage.getItem('agent_id'),
      first_name: localStorage.getItem('first_name'),
      last_name: localStorage.getItem('last_name'),
      role: localStorage.getItem('role')
    }
  }

  function ourReducer(draft, action) {
    // eslint-disable-next-line default-case
    console.log(action);
    switch (action.type) {
      case 'login':
        draft.loggedIn = true;
        draft.user = action.data;
        return;
      case 'authenticate':
        draft.loggedIn = true;
        draft.authenticated = true;
        draft.user.role = action.data.role;
        return;
      case 'unauthenticate':
        draft.loggedIn = true;
        draft.authenticated = false;
        draft.user.role = '';
        return;
      case 'logout':
        draft.loggedIn = false;
        draft.authenticated = false;
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    console.log(state)
    if (state.loggedIn) {
      localStorage.setItem('token', state.user.token);
      localStorage.setItem('first_name', state.user.first_name);
      localStorage.setItem('last_name', state.user.last_name);
      localStorage.setItem('role', state.user.role);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('first_name');
      localStorage.removeItem('last_name');
      localStorage.removeItem('role');
    }

    if (state.authenticated) {
      localStorage.setItem('authenticated', state.authenticated);
      localStorage.setItem('role', state.user.role);
    } else {
      localStorage.removeItem('authenticated');
      localStorage.removeItem('role');
    }
  }, [
    state.loggedIn,
    state.authenticated,
    state.user.token,
    state.user.first_name,
    state.user.last_name,
    state.user.role
  ])

  return (
    <StateContext.Provider value={state}>
      <AppContext.Provider value={dispatch}>
        <Routes>
          <Route path="/parking-registration" element={<ParkingForm />} exact={true} />
          <Route path="/parking-registration/:Cid" element={<ParkingForm />} exact={true} />
          <Route path="/" element={<UnderConstruction />} exact={true} />
          {/*
          <Route path="/" element={state.loggedIn ? <Navigate to={"/view-contacts"} />
          : <LoginScreen />} />
            <Route element={<ProtectedRoutes />}>
            <Route path="/view-contacts" element={<CrmPage />} exact={true} />
            <Route element={<AdminRoutes />}>
            </Route>
          </Route>
          */}
        </Routes>
      </AppContext.Provider>
    </StateContext.Provider>
  )
}
export default App;
