// ts
import React from "react";
import { Link } from 'react-router-dom'
import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function UnderConstruction(props) {
  axios.defaults.withCredentials = true;
  let navigate = useNavigate()

  return (
    <div className={"login-background"}>
      <Flex
        justify={"center"}
        align={"center"}
        overflow={"hidden"}
        flexDirection={"column"}
      >
        <Box
          w={"50vw"}
          minW={"xs"}
          minH={"200px"}
          marginTop={"1%"}
          bg={useColorModeValue("gray.100", "gray.900")}
          padding={"4%"}
          borderRadius={"10px"}
        >
          <Box align={"center"}>
            <Text fontSize={"lg"}> For parking registration: </Text>
            <Link fontSize={"lg"} to={'/parking-registration'}>
              Click here
            </Link>

            <br/>
            <br/>
            <Text fontSize={"lg"}>
              {" "}
              Website is under construction. Please check later. There will be
              some really cool stuff here soon.
            </Text>
            <Image
              src={
                "https://i.kym-cdn.com/photos/images/original/001/406/751/271.jpg"
              }
            />
          </Box>
        </Box>
      </Flex>
    </div>
  );
}

export default UnderConstruction;
